<template>
  <div>
    <search-tool :title="$t('roleList')" @search="search" @refresh="getList(currentPage)">
      <template #handle-buttons>
        <el-button size="small" type="primary" @click="$router.push('/admin/role/add')">
          {{ $t("handle.add") }}
        </el-button>
      </template>
    </search-tool>

    <el-table :data="data" v-loading="loading">
      <el-table-column prop="name" :label="$t('name')" width="200">
        <template slot-scope="scope">
          <div class="table-name-info">
            <svg-icon icon-class="role"></svg-icon>
            <div class="content">
              <div class="title ">
                {{ scope.row.name }}
              </div>
              <span class="desc">
                {{ moment(scope.row.createdAt).format("YYYY-MM-DD HH:mm:ss") }}
              </span>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column prop="organizationUUID" :label="$t('belongOrganization')">
        <template slot-scope="">
          <div class="title">{{ organizationName }}</div>
          <div class="desc">{{ organization }}</div>
        </template>
      </el-table-column>

      <el-table-column prop="description" :label="$t('description')" width="200">
        <template slot-scope="scope">
          <div class="margin-bottom: 10px;">
            <el-tag effect="dark" type="success" size="small" v-if="scope.row.name == 'Developer'">
              开发
            </el-tag>

            <el-tag effect="dark" size="small" v-if="scope.row.name == 'Operator'">
              运维
            </el-tag>

            <el-tag effect="dark" type="warning" size="small" v-if="scope.row.name == 'Owner'">
              管理
            </el-tag>

            <el-tag effect="dark" type="info" size="small" v-if="scope.row.name == 'ReadOnly'">
              只读
            </el-tag>
          </div>
          {{ scope.row.description }}
        </template>
      </el-table-column>

      <el-table-column prop="permissons" :label="$t('rolePermission')">
        <template slot-scope="scope">
          <div class="title">
            {{ mapPermission(scope.row.permissons) }}

            <el-popover placement="bottom" trigger="click">
              <ul class="permissons-list">
                <li v-for="(item, index) in permissonsList" :key="item">{{ index + 1 }}. {{ item }}</li>
              </ul>

              <a href="javascript:;" slot="reference" @click="permissionShow(scope.row)">查看</a>
            </el-popover>
          </div>
        </template>
      </el-table-column>

      <el-table-column width="100" :label="$t('handle.handle')" align="center">
        <template slot-scope="scope">
          <el-dropdown trigger="click" v-if="!['Developer', 'Operator', 'Owner', 'ReadOnly'].includes(scope.row.name)">
            <button class="table-row-handle">
              <i class="el-icon-more-outline"></i>
            </button>

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <div @click="edit(scope.row)">
                  {{ $t("handle.edit") }}
                </div>
              </el-dropdown-item>

              <el-dropdown-item>
                <div @click="deleteRole(scope.row)">
                  {{ $t("handle.delete") }}
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <span v-else>-</span>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :title="$t('handle.edit')" :visible.sync="dialogVisible" top="30px" :close-on-click-modal="false">
      <role-form :form="form" ref="form" v-if="dialogVisible" />
      <span slot="footer">
        <el-button type="primary" @click="submit" size="small" :loading="submitLoading">
          {{ $t("handle.submit") }}
        </el-button>
      </span>
    </el-dialog>

    <el-pagination
      layout=" prev, pager, next, sizes, total"
      :total="total"
      :page-size="currentPageSize"
      :page-sizes="[10, 15, 20, 30, 50]"
      :current-page="currentPage"
      @current-change="currentChange"
      @size-change="sizeChange"
    >
    </el-pagination>
  </div>
</template>

<script>
import { roleList, roleEdit, roleDelete } from "api/role";
import RoleForm from "./Form.vue";
import { cloneDeep, isArray } from "lodash";

export default {
  components: {
    RoleForm
  },

  data() {
    return {
      data: [],
      total: undefined,
      loading: false,
      currentPage: 1,
      currentPageSize: 10,
      searchText: "",
      form: {},
      dialogVisible: false,
      submitLoading: false,
      permissonsList: []
    };
  },

  methods: {
    getList(page = 1, pageSize = this.currentPageSize, search = [this.searchText]) {
      this.loading = true;
      this.currentPage = page;

      roleList(this.organization, { page, pageSize, search }).then(response => {
        if (response.code === 0) {
          this.loading = false;
          this.data = response.data.items;
          this.total = response.data.total;
        }
      });
    },

    search(searchText) {
      this.searchText = searchText;
      this.getList(1);
    },

    currentChange(page) {
      this.currentPage = page;
      this.getList(page);
    },

    sizeChange(size) {
      this.currentPageSize = size;
      this.getList(1, size);
    },

    edit(row) {
      this.form = cloneDeep(row);

      if (
        this.form.permissons &&
        isArray(JSON.parse(this.form.permissons)) &&
        JSON.parse(this.form.permissons).length > 0
      ) {
        this.form.permissons = JSON.parse(this.form.permissons).map(item => {
          return item.name;
        });
      }

      this.dialogVisible = true;
    },

    permissionShow(row) {
      this.permissonsList = JSON.parse(cloneDeep(row).permissons).map(item => {
        return item.name;
      });
    },

    mapPermission(row) {
      let permission = JSON.parse(row);
      let text = "";

      if (isArray(permission)) {
        permission.length > 10
          ? (text =
              permission
                .slice(0, 10)
                .map(item => item.name)
                .join(" / ") + "/...")
          : (text = permission.map(item => item.name).join(" / "));
      } else {
        text = "-";
      }

      return text;
    },

    submit() {
      this.$refs["form"].$refs["form"].validate(valid => {
        if (valid) {
          this.submitLoading = true;

          let data = this.form;

          let permissons = [];

          for (let item of this.form.permissons) {
            for (let per of this.$refs["form"].permissionList) {
              if (item === per.name) {
                permissons.push(per);
              }
            }
          }

          data.permissons = permissons;

          roleEdit(data).then(response => {
            this.submitLoading = false;
            if (response.code === 0) {
              this.getList(this.currentPage);
              this.dialogVisible = false;
            }
          });
        }
      });
    },

    deleteRole(item) {
      this.$confirm(this.$t("delete", [item.name]), this.$t("prompt"), {
        type: "warning"
      })
        .then(() => {
          roleDelete(this.organization, item.uuid).then(response => {
            if (response.code === 0) {
              this.getList(this.currentPage);
            }
          });
        })
        .catch(() => {});
    }
  },

  mounted() {
    this.getList();
  },

  computed: {
    organization() {
      return this.$store.state.app.organization;
    },

    organizationName() {
      return this.$store.state.app.organizationName;
    }
  }
};
</script>

<style lang="scss" scoped>
.permissons-list {
  max-height: 300px;
  overflow-y: scroll;

  li {
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
  }
}
</style>
